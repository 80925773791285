import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Kaufvertrag = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Der letzte Schritt beim Immobilienkauf: Dein Kaufvertrag" showCalc={false} />
            <Article>
                <p>
                    Du hast deine Traumimmobilie gefunden und auch schon für die Finanzierung gesorgt? Gratuliere! Auf
                    dem Weg zum Eigenheim gibt es jetzt noch eine letzte Hürde: den Kaufvertrag. Was dieser beinhaltet
                    und worauf du achten musst, erklären wir dir im folgenden Artikel.
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Kreditvergleich anfordern
                </a>
                <hr />

                <h2>In welcher Form muss ein Kaufvertrag erstellt werden?</h2>
                <p>
                    Der Kaufvertrag (auch Liegenschaftsvertrag) für den Erwerb einer Immobilie kann in Österreich
                    mündlich oder schriftlich abgeschlossen werden. Es besteht grundsätzlich Formfreiheit. Dennoch
                    solltest du den Vertrag in jedem Fall von einer Anwältin erstellen lassen: Die Schriftform sowie
                    eine möglichst präzise Formulierung der Rechte und Pflichten beider Parteien sind insbesondere im
                    Streitfall essenziell. Eine Rechtsanwältin zu konsultieren, ist übrigens schon deswegen
                    empfehlenswert, weil diese die{" "}
                    <Link to="/artikel/kaufnebenkosten/" target="_blank" rel="noreferrer noopener">
                        Kaufnebenkosten
                    </Link>{" "}
                    errechnen kann, die beim Kauf in Form von Steuern und Gebühren anfallen. Bei der Finanzierung hast
                    du dich schließlich auch vorab über sämtliche Kosten informiert, vielleicht aucht mithilfe eines{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechners
                    </Link>
                    .
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Beratungstermin vereinbaren
                </a>
                <hr />

                <h2>Was muss ein Kaufvertrag beinhalten?</h2>

                <h3>Die Vertragspartner:innen</h3>
                <p>
                    Sämtliche Parteien, die am Kauf der Immobilie beteiligt sind, müssen im Vertrag genannt werden:
                    Angeführt werden müssen dabei Name, Geburtsdatum, Adresse, Sozialversicherungsnummer sowie die
                    Staatsangehörigkeit. Fungieren jedoch Firmen als Vertragspartner, so müssen die Firmenbuchnummer und
                    die Vetragsberechtigten im Kaufvertrag aufscheinen.
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Kreditvergleich anfordern
                </a>
                <hr />

                <h3>Eine exakte Beschreibung des Gegenstandes</h3>
                <p>
                    Im Vertrag muss eine genaue Beschreibung der Immobilie bzw. des Grundstücks erfolgen, auf die bzw.
                    das sich dieser bezieht. Neben Flächenwidmungsplänen und eventuellen Baubewilligungen muss hierbei
                    die Adresse, die Fläche, das Entstehungsdatum sowie der Sanierungszustand des Objekts genannt
                    werden.
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Beratungsgespräch vereinbaren
                </a>
                <hr />

                <h3>Eine Mängelbeschreibung</h3>
                <p>
                    Der:die Verkäufer:in ist dazu verpflichtet, sämtliche Mängel des Objektes offenzulegen, sodass diese
                    im Kaufvertrag angegeben werden können. Geschieht das nicht, so können später entdeckte Schäden von
                    dem:der Käufer:in eingeklagt werden.
                </p>

                <h3>Vereinbarungen zu Kaufpreis und Zahlung</h3>
                <p>
                    Der Kaufvertrag beinhaltet sämtliche Informationen zu den Kosten der Transaktion: Der Preis der
                    Immobilie, Zahlungsziele, die Zinslast sowie weitere auf der Immobilie liegende Lasten sind allesamt
                    anzugeben. Es ist dabei empfehlenswert, die Bezahlung des Kaufpreises von einem:einer Treuhänder:in
                    durchführen zu lassen: Sobald der Kauf mit dem Grundbucheintrag rechtskräftig ist, bezahlt diese:r
                    den:die Verkäufer über ein eigens dafür eröffnetes Treuhandkonto. Auch der:die Treuhänder:in muss im
                    Vertrag genannt werden.
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Jetzt beraten lassen!
                </a>
                <hr />

                <h3>Anfallende Kosten und wer diese begleichen muss</h3>
                <p>
                    Auch die Frage, ab welchem Zeitpunkt der:die Käufer:in laufende Kosten zu begleichen hat, sollte im
                    Kaufvertrag geklärt werden. Wichtig ist auch zu beschließen, wer die Erstellung sowie die notarielle
                    Beglaubigung des Vertrags zu welchen Teilen bezahlt. Denn auch Notarkosten fallen an.
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Zum Expertengespräch
                </a>
                <hr />

                <h3>Rücktrittsbedingungen</h3>
                <p>
                    Auch wenn es nur unter besonderen Umständen möglich ist, von einem Liegenschaftsvertrag
                    zurückzutreten – etwa wenn die betreffende Immobilie am Tag der Übergabe von dem:der Vorbesitzer:in
                    noch nicht geräumt wurde: Im Vertrag sollte zumindest festgehalten werden, welche Maßnahmen eine
                    Partei ergreifen muss, um die andere über ein Problem zu informieren bzw. zur Behebung eines
                    Problems aufzufordern, ehe ein Rücktritt in Erwägung gezogen werden kann.
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Kreditvergleich anfordern
                </a>
                <hr />

                <h3>Dienstbarkeiten</h3>
                <p>
                    Im Zuge der Vertragsabwicklung sollten zudem sämtliche Dienstbarkeiten im Grundbuch vermerkt werden.
                    Dazu zählen etwa Wohn- und Mietrecht, Durchfahrtsberechtigungen und Wegrechte.
                </p>

                <h3>Die Aufsandungserklärung</h3>
                <p>
                    Unter der sogenannten Aufsandungserklärung ist eine notariell beglaubigte Erklärung des:der
                    Verkäufer:in darüber zu verstehen, dass die Eigentumsübertragung im{" "}
                    <Link to="/artikel/grundbuch-kosten/" target="_blank" rel="noreferrer noopener">
                        Grundbuch
                    </Link>{" "}
                    vorgenommen werden kann. Damit willigt der:die Verkäufer:in in die Überschreibung der Immobilie an
                    den:die Käufer:in ein.
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Zur kostenlosen Beratung
                </a>
                <hr />

                <h2>Liegenschaftsvertrag beim Hauskauf</h2>
                <p>
                    Beim Hauskauf innerhalb Österreichs gilt stets österreichisches Recht – unabhängig davon, ob der:die
                    Käufer:in über einen Wohnsitz im Inland verfügt oder nicht. Der Kaufvertrag sollte dabei zumindest
                    folgende Punkte enthalten:
                </p>
                <ul>
                    <li>Name und Adresse von Käufer:in und Verkäufer:in</li>
                    <li>Der Name der Gemeinde, in der sich die Immobilie befindet</li>
                    <li>Die Adresse der Immobilie</li>
                    <li>Angaben bezüglich Miteigentümer:innen oder Belastungen durch Dritte</li>
                    <li>Unterschriften der Vertragsparteien</li>
                </ul>
                <p>
                    Den Vertrag solltest du nach der Erstellung notariell beglaubigen lassen und überlegen, ob das
                    erworbene Haus als Haupt- oder Nebenwohnsitz dienen soll. Das ist sowohl für die Eintragung ins
                    Grundbuch als auch in steuerlicher Hinsicht zentral: Nur im Falle eines Hauptwohnsitzes bist du in
                    der Gemeinde, in der sich dein neues Haus befindet, auch steuerpflichtig.
                </p>
                <hr />

                <h2>Liegenschaftsvertrag beim Wohnungskauf</h2>
                <p>
                    Beim Erwerb einer Wohnung sollten zusätzliche Flächen unbedingt im Kaufvertrag vermerkt werden –
                    also auch Kellerabteile, Stellplätze und Grünflächen. Auch sämtliche Anzahlungen, die von dem:der
                    Verkäufer:in ggf. verlangt werden, sollten angeführt und zuvor auf ihre Adäquatheit geprüft werden.
                    Anschließend solltest du auch den Kaufvertrag über eine Wohnung von einem:einer Notar:in beglaubigen
                    lassen.
                </p>

                <h3>Noch nicht fertiggestellte Wohnungen</h3>
                <p>
                    Im Falle eines Kaufvertrags über eine Wohnung, die noch nicht fertiggestellt wurde, solltest du eine
                    Auflassungsvormerkung im Grundbuch eintragen lassen. Damit stellst du sicher, dass die Wohnung nach
                    ihrer Fertigstellung und vor ihrer Überschreibung an dich nicht an jemand anderen verkauft werden
                    kann.
                </p>

                <h3>Bereits genutzte Wohnungen</h3>
                <p>
                    Handelt es sich dagegen um eine Wohnung, die bereits genutzt wurde, dann informiere dich, wie es um
                    das Hausgeld steht – also jene Summe, die von dem:der aktuellen Eigentümer:in als Vorschuss an die
                    Wohnungseigentümergemeinschaft zu zahlen ist. Bedenke, dass du bei einem Zahlungsrückstand
                    schlimmstenfalls selbst für diesen Kostenpunkt aufkommen musst.
                </p>

                <h3>Vermietete Wohnungen</h3>
                <p>
                    Wird die Wohnung, die du kaufst, vermietet, dann kannst du selbst entscheiden, wann du den Platz
                    der:des bisherigen Vermieter:in einnimmst: Mit der Grundbucheintragung oder mit der Bezahlung des
                    Kaufpreises.
                </p>
                <hr />

                <h2>Welche Kosten kommen beim Kaufvertrag auf mich zu?</h2>
                <p>
                    Für die Erstellung des Liegenschaftsvertrags kannst du grundsätzlich mit Kosten in Höhe von 1 bis
                    2,5 % des Kaufpreises rechnen. Die Kosten variieren ja nach der Leistung, die die Rechtsanwältin
                    dabei zu erbringen hat: Sie steigen beispielsweise mit der Übernahme einer Treuhandschaft.
                </p>
                <p>
                    Auch die Makler:innenprovision solltest du beachten. Sie wird durch das Maklergesetz geregelt und
                    beträgt 1 bis 4 % des Kaufpreises: Maximal 4 % musst du bis zu einem Kaufpreis von 36 336,42 Euro
                    bezahlen. Höchstens 3 %, wenn du 48 488,51 Euro oder mehr für deine Immobilie bezahlst. Für
                    Kaufpreise, die zwischen diesen Beträgen liegen wird eine Provision in der Höhe von 1 453,46 Euro
                    fällig.
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Jetzt Angebot sichern
                </a>
                <hr />

                <h2>Noch Fragen?</h2>
                <p>
                    Solltest du weitere Fragen zum Kaufvertrag oder zu einem anderen Thema rund um den Immobilienerwerb
                    haben, dann melde dich jederzeit bei unseren Berater:innen. Sie helfen gerne weiter und sorgen
                    gemeinsam mit dir dafür, dass dein Kauf sich nicht unnötig in die Länge zieht und du so schnell wie
                    möglich in dein neues Zuhause einziehen kannst.
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Jetzt Beratungsgespräch vereinbaren
                </a>
            </Article>

            <BreadcrumbList page={"kaufvertrag"}></BreadcrumbList>
            <ArticleStructuredData
                page={"kaufvertrag"}
                heading={"Der letzte Schritt beim Immobilienkauf: Dein Kaufvertrag"}
            />
        </Layout>
    );
};

export default Kaufvertrag;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["header", "footer", "page.artikel.kaufvertrag"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
